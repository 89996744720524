














import { Component, Vue, Prop } from 'vue-property-decorator'

import Modal from '@/components/modals/Default.vue'
import TextInput from '@/components/inputs/Text.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import QuestionListItem from '@/components/implementation/question/QuestionListItem.vue'

import { EntityResource } from '@/models/implementations/entities/EntityResource'
import { userModule } from '@/store'

@Component({
  components: {
    Modal,
    TextInput,
    SmallLoader,
    QuestionListItem,
  }
})
export default class ImplementationEntityQuestions extends Vue {

  @Prop()
  private entity!: EntityResource

  private mounted(): void {
    const firstProcess = this.entity?.processes.find((process) => {
      return process.proposals.length > 0
    })

    if (!!firstProcess) {
      this.$router.replace({
        name: 'implementation-entity-proposal',
        params: {
          ...this.$route.params,
          proposal_id: firstProcess.proposals[0].id.toString()
        }
      })
    }
  }
}
